<template>
  <div class="wrapper">
    <div class="text-center">
      <a-input-search
        style="width: 580px"
        size="large"
        enter-button
        v-model="name"
        @search="search"
      ></a-input-search>
    </div>
    <list-filter
      :filters="filters"
      v-model="selected"
      @filter="filter"
    ></list-filter>
    <pagination-list
      type="e-partner-leader"
      :gutter="20"
      :size="3"
      :total="total"
      :dataSource="computedDataSource"
      :pagination.sync="pagination"
      @change="jump"
    ></pagination-list>
  </div>
</template>

<script>
import day from "dayjs";
import { upload } from "@/api";
import { getProInitiatorList } from "@/api/company.js";
export default {
  data() {
    return {
      filters: [{ label: "审核状态", optionFlag: "status" }],
      selected: [],
      name: "",
      dataSource: [],
      total: 0,
      pagination: {
        limit: 9,
        offset: 0,
      },
    };
  },
  inject: ["options"],
  created() {
    this.getList();
  },
  computed: {
    computedDataSource() {
      const status = this.options.status;

      return this.dataSource.map((item) => {
        const AuditStatus = item.Detail.Initiator.AuditStatus;
        const temp = {
          status: status[AuditStatus - 1].Name,
          submitTime: day
            .unix(item.Detail.Initiator.SubmitTime)
            .format("YYYY-MM-DD HH:mm"),
          name: "",
        };
        temp.id = item.ID;
        if (item.Detail && item.Detail.Info) {
          const info = item.Detail.Info;
          temp.name = info.Name;
          if (info.Logo && info.Logo.ID) {
            temp.logo = upload() + "/" + info.Logo.ID;
          }
        }

        return temp;
      });
    },
  },
  methods: {
    generateParams() {
      const status = this.selected.find((item) => item.flag === "status");
      const temp = {
        name: this.name,
        status: status && status.id ? status.id : undefined,
      };
      Object.assign(temp, this.pagination);
      return temp;
    },
    getProInitiatorList(temp) {
      getProInitiatorList(temp).then(({ data }) => {
        this.total = data.count;
        this.dataSource = data.rows || [];
      });
    },
    getList() {
      const temp = this.generateParams();
      this.getProInitiatorList(temp);
    },
    search() {
      this.$set(this.pagination, "offset", 0);
      this.getList();
    },
    filter() {
      this.$set(this.pagination, "offset", 0);
      this.getList();
    },
    jump() {
      this.getList();
    },
  },
};
</script>

<style scoped>
.wrapper {
}
</style>